import { combineReducers } from 'redux';
import activities from './activities';
import alerts from './alerts';
import campaigns from './campaign';
import configuration from './configuration';
import deviceList from './device-list';
import devices from './devices';
import issueLabels from './issue-labels';
import issues from './issues';
import models from './models';
import operatingSystem from './operating-system';
import osVersion from './os-versions';
import projects from './projects';
import testSuite from './test-suite';
import users from './users';

const reducers = combineReducers({
    activities,
    alerts,
    campaigns,
    configuration,
    deviceList,
    devices,
    issueLabels,
    issues,
    models,
    operatingSystem,
    osVersion,
    projects,
    testSuite,
    users,
});

export default reducers;
